import SecureLS from 'secure-ls'

let ls = new SecureLS({
  encodingType: 'rc4',
  isCompression: false,
})

const lStorage = {
  get(key, isObj = undefined) {
    let v = ls.get(key)
    try {
      if (v) JSON.parse(v)
    } catch (error) {
      // console.log(key)
      return v
    }

    // if (isObj != undefined && isObj) return JSON.parse(v)
  },

  set(key, val, isObj = undefined) {
    if (isObj != undefined && isObj) val = JSON.stringify(val)
    return ls.set(key, val)
  },

  remove(key) {
    return ls.remove(key)
  },

  removeAll() {
    return ls.removeAll()
  },
}

export default lStorage
