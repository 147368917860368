import http from './http'

const mainUri = '/api/v2/profile'

export function get() {
  return http({
    url: `${mainUri}/info`,
    method: 'get',
  })
}

export function logs() {
  return http({
    url: `${mainUri}/logs`,
    method: 'get',
  })
}

export function updateProfilePicture(data) {
  return http({
    url: `${mainUri}/profilePicture`,
    method: 'post',
    data,
  })
}

export function updatePassword(data) {
  return http({
    url: `${mainUri}/password`,
    method: 'post',
    data,
  })
}

export function updatePhone(data) {
  return http({
    url: `${mainUri}/phone`,
    method: 'post',
    data,
  })
}
