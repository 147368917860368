import http from './http'

const mainUri = '/api'
export function login(data) {
  return http({
    url: `${mainUri}/auth`,
    method: 'post',
    data: data,
  })
}

export function generateOtp() {
  return http({
    url: `${mainUri}/v2/genOtp`,
    method: 'post',
  })
}

export function verify(data) {
  return http({
    url: `${mainUri}/v2/verifyOtp`,
    method: 'post',
    data: data,
  })
}

export function register(data) {
  return http({
    url: `${mainUri}/register`,
    method: 'post',
    data: data,
  })
}

export function logout(data) {
  return http({
    url: `${mainUri}/v2/logout`,
    method: 'post',
  })
}
