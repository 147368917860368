<template>
  <v-container>
    <v-row>
      <v-col> Home Page </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
}
</script>
