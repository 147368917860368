import ls from '@/utils/ls'
import { getb, getd, gets } from '@/api/deps'
import { count as commandApprovalCount } from '@/api/command_approval'
import { count as roleApprovalCount } from '@/api/userrolerequest'
const appfilter = {
  namespaced: true,
  state: {
    showTraffic: true,
    bureauId: null,
    divisionId: null,
    stationId: null,
    bureaus: [],
    divisions: [],
    stations: [],
    dateStart: null,
    dateEnd: null,
    datesSelected: [],
    nearByDistance: ls.get('nbdt'),
    commandApproval: ls.get('cmdApp') || 0,
    roleApproval: ls.get('roleApp') || 0,
  },
  mutations: {
    setShowTraffic(state, v) {
      state.showTraffic = v
    },
    setBureaus(state, v) {
      state.bureaus = v
    },
    setBureau(state, v) {
      state.bureauId = v
    },
    setDivisions(state, v) {
      state.divisions = v
    },
    setDivision(state, v) {
      state.divisionId = v
    },
    setStations(state, v) {
      state.stations = v
    },
    setStation(state, v) {
      state.stationId = v
    },
    setDateStart(state, v) {
      state.dateStart = v
    },
    setDateEnd(state, v) {
      state.dateEnd = v
    },
    setDatesSelected(state, v) {
      state.datesSelected = v
    },
    setNearByDistance(state, v) {
      state.nearByDistance = v
      ls.set('nbdt', v)
    },
    setCommandApprovalCount(state, v) {
      state.commandApproval = v
      ls.set('cmdApp', v)
    },
    setRoleApprovalCount(state, v) {
      state.roleApproval = v
      ls.set('roleApp', v)
    },
    clear_all_state(state) {
      state = Object.assign(
        {},
        {
          showTraffic: true,
          bureauId: null,
          divisionId: null,
          stationId: null,
          bureaus: [],
          divisions: [],
          stations: [],
          dateStart: null,
          dateEnd: null,
          datesSelected: [],
          nearByDistance: ls.get('nbdt'),
        }
      )
    },
  },
  actions: {
    getBureau({ commit, state }) {
      getb().then(res => {
        commit('setBureaus', res)
        ls.set('bureaus', res)
      })
    },
    getDivision({ commit, state }) {
      getd(state.bureauId).then(res => {
        commit('setDivisions', res)
      })
    },
    getStation({ commit, state }) {
      gets(state.divisionId).then(res => {
        commit('setStations', res)
      })
    },
    getCommandApproval({ commit, state }) {
      commandApprovalCount().then(res => {
        commit('setCommandApprovalCount', res?.count)
      })
    },
    getRoleApproval({ commit, state }) {
      roleApprovalCount().then(res => {
        commit('setRoleApprovalCount', res?.count)
      })
    },
  },
  getters: {
    showTraffic: state => state?.showTraffic,
    bureauId: state => state?.bureauId,
    divisionId: state => state?.divisionId,
    stationId: state => state?.stationId,
    bureaus: state => state?.bureaus,
    divisions: state => state?.divisions,
    stations: state => state?.stations,
    dateStart: state => state?.dateStart,
    dateEnd: state => state?.dateEnd,
    datesSelected: state => state?.datesSelected,
    commandApproval: state => state?.commandApproval,
    roleApproval: state => state?.roleApproval,
  },
}

export default appfilter
