import Vue from 'vue'
// gmaps
import * as GmapVue from 'gmap-vue'

import axios from 'axios'
// AIzaSyB4O5sYSKcqYA2UQGXLM5TlkcMqrVte3xs -> real_old1
// AIzaSyC6NPEhj26w4q9un9MZt9y2T195YERayrY -> real
// AIzaSyDUmCe8uHzCg9sMECsf5_HbRh9dlj5V3Uw -> test

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GMAP_API_KEY,
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
  installComponents: true,
})

import GmapCluster from 'gmap-vue/dist/components/cluster'
Vue.component('gmap-cluster', GmapCluster)
