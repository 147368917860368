import { get as apiGet, updateProfilePicture, updatePhone } from '@/api/profile'
import ls from '@/utils/ls'

const getAvatarText = (fname, lname) => {
  const regex = /[ก-ฮ]/g
  return fname?.match(regex)[0] + lname?.match(regex)[0]
}

const UserModule = {
  namespaced: true,
  state: {
    name: ls.get('name'),
    position: ls.get('position'),
    role: ls.get('role'),
    avatarText: ls.get('avatar'),
    avatarPicture: ls.get('avatarPhoto'),
    station: typeof ls.get('userStation') == Object ? '' : '',
    phone: ls.get('userPhone'),
    level_name: ls.get('level_name'),
  },
  mutations: {
    SET_INFO(state, info) {
      state.name = info.name
      ls.set('name', info.name)
      state.position = info.position
      ls.set('position', info.position)
      state.level_name = info.level_name
      ls.set('level_name', info.level_name)
      state.role = info.roles[0]
      ls.set('role', info.roles[0])
      const atxt = getAvatarText(info.fname || info.first_name, info.lname || info.last_name)
      state.avatarText = atxt
      ls.set('avatar', atxt)
      // ls.set('avatarPhoto', info.profilePicture)
      // state.avatarPicture = info.profilePicture
      ls.set('userStation', info.station, true)
      state.station = info.station
      ls.set('userPhone', info.phone)
      state.phone = info.phone
    },
    update_profile_picture(state, info) {
      ls.set('avatarPhoto', info)
      state.avatarPicture = info
    },
    update_phone(state, info) {
      ls.set('userPhone', info)
      state.phone = info
    },
    remove_all_info(state) {
      state.name = ''
      state.level_name = ''
      state.position = ''
      state.role = null
      state.avatarText = 'XX'
      state.profilePicture = ''
    },
  },
  actions: {
    get_user_info({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiGet()
          .then(res => {
            commit('SET_INFO', res)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    set_user_info({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_INFO', data)
        resolve(true)
      })
    },
    update_profile_picture({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        updateProfilePicture(data)
          .then(res => {
            if (res.success) commit('update_profile_picture', res.image)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    update_phone({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        updatePhone(data)
          .then(res => {
            if (res.success) commit('update_phone', data.phone)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    name: state => state.name,
    position: state => state.position,
    role: state => state.role,
    avatar: state => state.avatarText,
    avatarPicture: state => state.avatarPicture,
    station: state => state.station,
    phone: state => state.phone,
    level_name: state => state.level_name,
    stationName: state => state.station?.name,
  },
}

export default UserModule
