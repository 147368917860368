import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // layout 1 (user) หรือหน้าบ้านหรืออื่นๆ
  {
    path: '/',
    component: () => import('@/layouts/sidemenu_style'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/views/dashboard'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: 'command',
        name: 'Command',
        component: () => import('@/views/command'),
        meta: {
          title: 'คำสั่ง',
          breadcrumbs: [{ text: 'คำสั่ง', disabled: true, to: '/command' }],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },
      {
        path: 'command/:id',
        name: 'CommandMod',
        component: () => import('@/views/command/header'),
        meta: {
          title: 'รายละเอียด',
          breadcrumbs: [
            {
              text: 'คำสั่ง',
              disabled: false,
              exact: true,
              to: { name: 'Command' },
            },
            { text: 'รายละเอียด', disabled: true, to: '' },
          ],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },
      {
        path: 'command-approval',
        name: 'CommandApproval',
        component: () => import('@/views/command_approval'),
        meta: {
          title: 'อนุมัติคำสั่ง',
          breadcrumbs: [{ text: 'อนุมัติคำสั่ง', disabled: true, to: '/command-approval' }],
          roles: ['super-admin', 'division-admin'],
        },
      },
      {
        path: 'command-approval/:id',
        name: 'CommandDetailApproval',
        component: () => import('@/views/command_approval/header'),
        meta: {
          title: 'รายละเอียด',
          breadcrumbs: [
            {
              text: 'ขออนุมัติคำสั่ง',
              disabled: false,
              exact: true,
              to: { name: 'CommandApproval' },
            },
            { text: 'รายละเอียด', disabled: true, to: '' },
          ],
          roles: ['super-admin', 'division-admin'],
        },
      },
      {
        path: 'today',
        name: 'Today',
        component: () => import('@/views/today'),
        meta: {
          title: 'จุดตรวจวันนี้',
          breadcrumbs: [{ text: 'จุดตรวจวันนี้', disabled: true, to: '/today' }],
          roles: ['super-admin', 'commander', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },

      {
        path: 'all',
        name: 'AllCheckpoint',
        component: () => import('@/views/allcp'),
        meta: {
          title: 'จุดตรวจทั้งหมด',
          breadcrumbs: [{ text: 'จุดตรวจทั้งหมด', disabled: true, to: '/today' }],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin', 'commander', 'evaluator'],
        },
      },
      {
        path: 'officer',
        name: 'Officer',
        component: () => import('@/views/officer'),
        meta: {
          title: 'เจ้าหน้าที่',
          breadcrumbs: [
            {
              text: 'เจ้าหน้าที่',
              disabled: false,
              exact: true,
              to: { name: 'Officer' },
            },
          ],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },
      {
        path: 'cctv',
        name: 'CCTV',
        component: () => import('@/views/cctv'),
        meta: {
          title: 'กล้องวงจรปิด',
          breadcrumbs: [
            {
              text: 'กล้องวงจรปิด',
              disabled: false,
              exact: true,
              to: { name: 'cctv' },
            },
          ],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/user'),
        meta: {
          title: 'ผู้ใช้งาน',
          breadcrumbs: [
            {
              text: 'ผู้ใช้งาน',
              disabled: false,
              exact: true,
              to: { name: 'Officer' },
            },
          ],
          roles: ['super-admin'],
        },
      },
      {
        path: 'role-approve',
        name: 'RoleApprove',
        component: () => import('@/views/role_request'),
        meta: {
          title: 'อนุมัติสิทธิ์',
          breadcrumbs: [
            {
              text: 'อนุมัติสิทธิ์',
              disabled: false,
              exact: true,
              to: { name: 'RoleApprove' },
            },
          ],
          roles: ['super-admin'],
        },
      },
      {
        path: 'drunkreport',
        name: 'Drunk',
        component: () => import('@/views/drunkreport'),
        meta: {
          title: 'ตรวจเมา',
          breadcrumbs: [
            {
              text: 'รายงานผลการตรวจวัดปริมาณแอลกอฮอล์',
              disabled: false,
              exact: true,
              to: { name: 'Drunk' },
            },
          ],
        },
      },
      {
        path: 'arrestreport',
        name: 'ArrestReport',
        component: () => import('@/views/arrestreport'),
        meta: {
          title: 'รายงานผลการปฏิบัติ',
          breadcrumbs: [
            {
              text: 'รายงานผลการปฏิบัติ',
              disabled: false,
              exact: true,
              to: { name: 'ArrestReport' },
            },
          ],
        },
      },
      {
        path: 'satisfactionreport',
        name: 'SatisfactionReport',
        component: () => import('@/views/satisfactionreport'),
        meta: {
          title: 'รายงานผลการประเมินความพึงพอใจจากประชาชน',
          breadcrumbs: [
            {
              text: 'รายงานผลการประเมินความพึงพอใจจากประชาชน',
              disabled: false,
              exact: true,
              to: { name: 'SatisfactionReport' },
            },
          ],
        },
      },
      {
        path: 'arrestedvehiclereport',
        name: 'ArrestedVehicleReport',
        component: () => import('@/views/arrestedvehiclereport'),
        meta: {
          title: 'รายงานจำนวนรถที่พบการกระทำความผิด',
          breadcrumbs: [
            {
              text: 'รายงานจำนวนรถที่พบการกระทำความผิด',
              disabled: false,
              exact: true,
              to: { name: 'ArrestedVehicleReport' },
            },
          ],
        },
      },
      {
        path: 'evaluation',
        name: 'Evaluation',
        component: () => import('@/views/evaluation'),
        meta: {
          title: 'ตรวจ/ประเมินผลจุดตรวจ',
          breadcrumbs: [
            {
              text: 'ตรวจ/ประเมินผลจุดตรวจ',
              disabled: false,
              exact: true,
              to: { name: 'Evaluation' },
            },
          ],
        },
      },
      {
        path: 'ptmreport',
        name: 'PtmReport',
        component: () => import('@/views/ptm'),
        meta: {
          title: 'ผลการปฏิบัติ PTM',
          breadcrumbs: [
            {
              text: 'ผลการปฏิบัติ PTM',
              disabled: false,
              exact: true,
              to: { name: 'PtmReport' },
            },
          ],
        },
      },
      {
        path: 'pcp',
        name: 'permanentCheckpoint',
        component: () => import('@/views/permanent_checkpoint'),
        meta: {
          title: 'ด่านตรวจถาวร',
          breadcrumbs: [
            {
              text: 'ด่านตรวจถาวร',
              disabled: false,
              exact: true,
              to: { name: 'permanentCheckpoint' },
            },
          ],
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/about'),
        meta: {
          title: 'เกี่ยวกับ',
          breadcrumbs: [{ text: 'เกี่ยวกับ', disabled: true, to: '/about' }],
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/profile'),
        meta: {
          title: 'Profile',
          breadcrumbs: [{ text: 'User Profile', disabled: true, to: '/profile' }],
        },
      },
    ],
    meta: {
      // สำหรับไว้ตรวจสอบก่อนจะเข้าถึง route
      requiresAuth: true,
    },
  },

  // login route
  { path: '/login', name: 'Login', component: () => import('../views/login') },
  { path: '/satisfaction', name: 'Satisfaction', component: () => import('../views/satisfaction') },
  { path: '/otp', name: 'OTP', component: () => import('../views/login/otp') },
  // register
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register'),
  },

  // สำหรับ layout ที่ 2 อาจจะเป็นสำหรับ admin
  {
    path: '/admin',
    component: () => {},
    children: [
      // Pages ต่างๆ
    ],
    meta: {
      // สำหรับไว้ตรวจสอบก่อนจะเข้าถึง route
      requiresAuth: true,
    },
  },
  // 404
  { path: '/503', name: '503', component: () => import('../views/error/503') },

  // 404
  { path: '*', name: '404', component: () => import('../views/error/404') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // set title
  document.title = `${to.meta.title || 'TPCC'}-TPCC-2.0`
  // console.log(to.matched)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // ถ้าเข้าระบบแล้ว
    if (store.getters['Auth/isLoggedIn']) {
      // หาว่ากำหนด Role หรือเปล่า
      const routeRole = to.matched.find(r => r.meta.roles)
      if (routeRole != undefined) {
        // ถ้ากำหนด ตรวจสอบว่า user เข้าได้มั้ย
        if (routeRole.meta.roles.some(role => role == store.getters['User/role'])) {
          // ถ้าได้ก็ไปต่อ
          next()
          return
        } else {
          // ถ้าไม่ได้ กลับไป Dashboard
          next({ name: 'Dashboard' })
          return
        }
      } else {
        // ถ้าไม่กำหนดก็เข้าได้หมด
        next()
        return
      }
    }
    // ยังก็ไปล็อกอิน
    next({ name: 'Login' })
  } else {
    // ถ้าไม่ต้องการ การล็อกอิน
    next()
  }
})

export default router
