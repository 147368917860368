import http from './http'

export function getb() {
  return http({
    url: `/api/v2/bureau`,
    method: 'get',
  })
}

export function getbAll() {
  return http({
    url: `/api/v2/bureau-all`,
    method: 'get',
  })
}

export function getd(bid) {
  return http({
    url: `/api/v2/division`,
    method: 'get',
    params: {
      bid: bid,
    },
  })
}

export function getdAll(bid) {
  return http({
    url: `/api/v2/division-all`,
    method: 'get',
    params: {
      bid: bid,
    },
  })
}

export function gets(did) {
  return http({
    url: `/api/v2/station`,
    method: 'get',
    params: {
      did: did,
    },
  })
}

export function getsAll(did) {
  return http({
    url: `/api/v2/station-all`,
    method: 'get',
    params: {
      did: did,
    },
  })
}

