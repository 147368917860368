import { login as apiLogin, generateOtp, verify, logout as apiLogout } from '@/api/auth'
import lStorage from '@/utils/ls'

const Auth = {
  namespaced: true,
  state: {
    status: '',
    token: lStorage.get('tk') || '',
    pre_token: '',
    isLoggedIn: false,
    waitForVerify: true,
    tokenId: '',
    tokenMobile: '',
  },
  mutations: {
    AUTH_SUCCESS(state, token) {
      state.status = 'success'
      state.pre_token = token
      state.waitForVerify = true
    },
    VERIFIED(state, token) {
      state.waitForVerify = false
      state.isLoggedIn = true
      state.token = token
      lStorage.set('tk', token)
      state.pre_token = ''
    },
    AUTH_ERROR(state) {
      state.status = 'error'
    },
    AUTH_LOGGED_OUT(state) {
      state.status = 'logged-out'
      state.token = ''
      state.isLoggedIn = false
    },
    remove_token(state) {
      lStorage.remove('tk')
      state.token = ''
      state.waitForVerify = false
      state.isLoggedIn = false
      state.token = ''
      state.pre_token = ''
      state.status = ''
    },
  },
  actions: {
    login({ commit, state }, credentials) {
      return new Promise((resolve, reject) => {
        apiLogin(credentials)
          .then(res => {

            const userInfo = res.user;
            // auth failure
            if (res.access_token == undefined || res.access_token == null || res.access_token == '') {
              commit('AUTH_ERROR')
              lStorage.remove('pre-tk')
              lStorage.remove('tk')
              reject(res)
              return
            }
            // auth success set ac_tk
            commit('AUTH_SUCCESS', res.access_token)
            // save to local (for verify otp)
            lStorage.set('pre-tk', res.access_token)
            // lStorage.set('tk', res.access_token)

            //generate otp
            generateOtp()
              .then(res => {
                // console.log(res)
                if (res.statusCode == 113) {
                  state.tokenId = res.id
                  state.tokenMobile = res.mobile
                  resolve(res)
                }
                if (res.statusCode == 112) {
                  // save to local (for verify otp)
                  lStorage.set('tk', state.pre_token)
                  // auth success set ac_tk
                  commit('VERIFIED', state.pre_token)

                  // remove pre token
                  lStorage.remove('pre-tk')

                  if (userInfo) {
                    // call set user Info
                    this.dispatch('User/set_user_info', userInfo)
                      .then(() => {
                        // wait for user info set
                        console.log('user info set')
                        resolve(res)
                      })
                      .catch(err => {
                        reject(err)
                      })
                  } else {
                    // call get user Info
                    this.dispatch('User/get_user_info')
                      .then(() => {
                        // wait for user info set
                        resolve(res)
                      })
                      .catch(err => {
                        reject(err)
                      })
                  }
                }
              })
              .catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            commit('AUTH_ERROR')
            console.log('error:' + error)
            lStorage.remove('tk')
            reject(error)
          })
      })
    },
    verifyOtp({ commit, state }, code) {
      return new Promise((resolve, reject) => {
        verify(code)
          .then(res => {
            // verify failure
            if (!res.success) {
              reject(res)
              return
            }
            // save to local (for verify otp)
            lStorage.set('tk', state.pre_token)
            // auth success set ac_tk
            commit('VERIFIED', state.pre_token)

            // remove pre token
            lStorage.remove('pre-tk')
            // call get user Info
            this.dispatch('User/get_user_info')
              .then(() => {
                // wait for user info set
                resolve(res.data)
              })
              .catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        lStorage.removeAll()
        commit('remove_token')
        commit('User/remove_all_info', null, { root: true })
        commit('Appfilter/clear_all_state', null, { root: true })
        apiLogout().then(res => {
          if (res.success) {
            commit('AUTH_LOGGED_OUT')
            resolve(res)
          }
        })
          .catch(error => {

          })
      })
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    waitForVerify: state => state.waitForVerify,
    authStatus: state => state.status,
    accessToken: state => state.token,
    preAccessToken: state => state.pre_token,
  },
}

export default Auth
