import http from './http'

const mainUri = '/api/v2/userRoleRequest'

export function list(params) {
  return http({
    url: `${mainUri}`,
    method: 'get',
    params: params,
  })
}

export function unApprovedList(params) {
  return http({
    url: `${mainUri}UnApproved`,
    method: 'get',
    params: params,
  })
}

export function count() {
  return http({
    url: `${mainUri}Count`,
    method: 'get',
  })
}

export function store(data) {
  return http({
    url: `${mainUri}`,
    method: 'post',
    data: data,
  })
}

export function update(data) {
  return http({
    url: `${mainUri}/${data.id}`,
    method: 'patch',
    data: data,
  })
}

export function destroy(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'delete',
  })
}

export function show(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'get',
  })
}
