<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click.prevent.stop="onExport" color="indigo darken-3" v-on="on" v-bind="attrs">
        <v-icon>mdi-file-excel</v-icon>
        XLSX
      </v-btn>
    </template>
    <span>ส่งออกเป็นไฟล์ Excel</span>
  </v-tooltip>
</template>

<script>
import XLSX from 'xlsx' // import xlsx

export default {
  name: 'app',
  data() {
    return {}
  },
  methods: {
    // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
    onExport() {
      const dataWS = XLSX.utils.table_to_book(this.ele)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export.xlsx')
    },
  },
  props: {
    value: {},
    ele: {},
  },
}
</script>
