import Vue from 'vue'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'

Vue.use(CxltToastr, {
  position: 'top center',
  showDuration: 100,
  timeOut: 3000,
  hideDuration: 100,
})
