import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import th from 'vuetify/lib/locale/th'
// import '@/sass/variables.scss'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { th },
    current: 'th',
  },
})
