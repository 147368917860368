import store from '@/store'

export default {
  inserted(el, binding) {
    const { value } = binding
    const userRole = store.getters['User/role']

    if (value && Array.isArray(value) && value.length > 0) {
      const roleRequire = value
      // console.log('required role :' + roleRequire.join(','))
      // console.log('user role is : ' + userRole)

      // เข้าได้ทุกสิทธิ์
      if (roleRequire[0] == '*') return

      // ตรวจสอบว่ามีสิทธิหรือไม่ โดยหาจาก Array Role requirement ถ้าตรงกับ user role ก็แสดงว่ามีสิทธิ์
      const hasPermission = roleRequire.some(r => r == userRole)
      // console.log('user hasPm: ' + hasPermission)

      // ถ้าไม่มีสิทธิ์ให้ลบ element ทิ้ง
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`need role! Like v-role="[admin,commander]"`)
    }
  },
}
