import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import confirmDialog from './components/confirmDialog'
import excelBtn from './components/excelBtn'
// plugins
import './plugins/gmap'
import './plugins/vuetify-mask.js'
import './plugins/vuetify-toast'
import './plugins/moment'
import './plugins/vue-fullscreen'
import './plugins/html-to-paper'
import '@babel/polyfill'

Vue.config.productionTip = false

// my confirm dialog
Vue.component('bjp-confirm-dialog', confirmDialog)

Vue.component('excel-btn', excelBtn)

// axios
Vue.use(VueAxios, axios)

// v-Role
import roleDirective from './directive/role'
Vue.directive('role', roleDirective)

import LongdoMap from 'longdo-map-vue' 
Vue.use(LongdoMap, {     
  load: {         
       apiKey: '7ae54d646e284c217f1cebe595e0f448'     
  } 
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
