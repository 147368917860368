import axios from 'axios'
import store from '@/store'
import router from '@/router'

const baseUrl = process.env.VUE_APP_TPCC_URL // 'https://tpcc.police.go.th/2021'

let axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 0,
})

// ทำก่อนส่ง request
axiosInstance.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'

    // ตั้ง Authorization
    if (store.getters['Auth/isLoggedIn']) {
      const token = store.getters['Auth/accessToken']
      config.headers['Authorization'] = `Bearer ${token}`
    }

    // console.log(store.getters['Auth/waitForVerify'] && !store.getters['Auth/isLoggedIn'])

    if (store.getters['Auth/waitForVerify'] && !store.getters['Auth/isLoggedIn']) {
      const token = store.getters['Auth/preAccessToken']
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// แทรกระหว่างส่ง response กลับไปให้ component
axiosInstance.interceptors.response.use(
  function(response) {
    // ตรวจสอบว่ามี response and return to component
    return response.data
  },
  function(error) {
    if (error.response === undefined) return Promise.reject(error)
    if (error.response.status === 401) {
      store.commit('Auth/remove_token')
      router.replace({ name: 'Login' }).catch(()=>{})
      return
      // return Promise.reject(error)
    } else if (error.response.status === 503) {
      router.replace({ name: '503' })
      return
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
