import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import appfilter from './modules/appfilter'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Appfilter: appfilter,
    Auth: auth,
    User: user,
  },
})
